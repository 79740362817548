jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
     if( !$('body html').is(':animated') ) {
        $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });

  $(".voice_ttl").on("click", function() {
    if($(this).next().css("display") == "none"){
      $(this).next().slideDown();
      $(this).addClass("active");//追加部分
    } 
    else {
      $(".voice_ttl").next().slideUp();
      $(".voice_ttl").removeClass("active");//追加部分 
    }
  });
  // var nav = $("#nav").height();
    var header =  $('#header').outerHeight();
  $(".close_btn").on("click", function() {
    $(".voice_ttl").next().slideUp(800);
    $('body,html').animate({scrollTop:$(this).parents(".accordion").offset().top - header}, 800, 'swing');
    $(".voice_ttl").removeClass("active");//追加部分
  });
  
  var $backBtn = $('#back-btn');
    $backBtn.on('click', function() {
    history.back();
  });

});